import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import permission from "@/store/permission";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        base_url: "https://api.cjsmp.com/",
        token: localStorage.getItem("token") || null,
        user_name: localStorage.getItem("user_name") || null,
        email: localStorage.getItem("email") || null,
        phone: localStorage.getItem("phone") || null,
        photo: localStorage.getItem("photo") || null,
        getPermissions: {},
        getDivision: {},
        getDistrict: {},
        getUpazila: {},
        getAllDistrict: {},
    },
    actions: {
        removeToken(context) {
            context.commit("removeToken");
        },
        setToken(context, data) {
            context.commit("setToken", data);
        },
        CheckAuth(content) {
            axios.defaults.headers.common["Authorization"] =
                "Bearer " + localStorage.getItem("token");
            axios
                .get("/app/check")
                .then((res) => {
                    if (!res.data.status) {
                        localStorage.removeItem("token");
                        content.commit("removeToken");
                        router.push("/login");
                    }
                })
                .catch((e) => {
                    if (e.response.status === 401) {
                        localStorage.removeItem("token");
                        content.commit("removeToken");
                        router.push("/login");
                    }
                });
        },
        getDivision(content) {
            axios
                .get("/app/division")
                .then((res) => {
                    content.commit("getDivision", res.data.divisions);
                })
                .catch((e) => {
                    this.e(e.data.error);
                });
        },
        getDistrict(content, queryParams) {
            axios
                .get(`/app/district${queryParams}`)
                .then((res) => {
                    content.commit("getDistrict", res.data.districts);
                })
                .catch((e) => {
                    this.e(e.data.error);
                });
        },
        getUpazila(content, queryParams) {
            axios
                .get(`/app/upazila${queryParams}`)
                .then((res) => {
                    content.commit("getUpazila", res.data.upazilas);
                })
                .catch((e) => {
                    this.e(e.data.error);
                });
        },
        getAllDistrict(content) {
            axios
                .get("/app/all-district")
                .then((res) => {
                    content.commit("getAllDistrict", res.data.districts);
                })
                .catch((e) => {
                    this.e(e.data.error);
                });
        },
    },
    getters: {
        loggedIn(state) {
            return state.token !== null;
        },
        getPermissions(state) {
            return state.getPermissions;
        },
        getDivision(state) {
            return state.getDivision;
        },
        getDistrict(state) {
            return state.getDistrict;
        },
        getUpazila(state) {
            return state.getUpazila;
        },
        getAllDistrict(state) {
            return state.getAllDistrict;
        },
    },
    mutations: {
        setToken(state, data) {
            state.token = data.token;
            state.user_name = data.name;
            state.email = data.email;
            state.phone = data.phone;
            state.photo = data.photo;
            state.onboard = data.onboard_status;
        },
        removeToken(state) {
            state.token = null;
            state.user_name = null;
            state.email = null;
            state.phone = null;
            state.photo = null;
            state.onboard = null;
        },
        getPermissions(state, data) {
            return (state.getPermissions = data);
        },
        getDivision(state, data) {
            return (state.getDivision = data);
        },
        getDistrict(state, data) {
            return (state.getDistrict = data);
        },
        getUpazila(state, data) {
            return (state.getUpazila = data);
        },
        getAllDistrict(state, data) {
            return (state.getAllDistrict = data);
        },
    },
    modules: {
        app,
        appConfig,
        verticalMenu,
        permission,
    },
    strict: process.env.DEV,
});

export default {
   state: {},
   getters: {},
   actions: {
      getPermissions(content) {
         axios
            .get("/app/permission")
            .then((res) => {
               let permissions = {
                  role: {
                     access: false,
                     create: false,
                     edit: false,
                     delete: false,
                  },
                  report:{
                     skillWiseCandidate: false,
                     companyWiseJobPlacement: false
                  },
                  url_info: {
                     access: false,
                     create: false,
                     edit: false,
                     delete: false,
                  },
                  skill: {
                     access: false,
                     create: false,
                     edit: false,
                     delete: false,
                  },
                  level: {
                     access: false,
                     create: false,
                     edit: false,
                     delete: false,
                  },
                  industry: {
                     access: false,
                     create: false,
                     edit: false,
                     delete: false,
                  },
                  user: {
                     access: false,
                     create: false,
                     edit: false,
                     delete: false,
                  },
                  division: {
                     access: false,
                     create: false,
                     edit: false,
                     delete: false,
                  },
                  district: {
                     access: false,
                     create: false,
                     edit: false,
                     delete: false,
                  },
                  upazila: {
                     access: false,
                     create: false,
                     edit: false,
                     delete: false,
                  },
                  thread: {
                     access: false,
                     manage: false,
                  },
                  candidate: {
                     access: false,
                     create: false,
                     edit: false,
                     delete: false,
                     approved: false,
                  },
                  jobs: {
                     access: false,
                     create: false,
                     edit: false,
                     delete: false,
                  },
                  company: {
                     access: false,
                     create: false,
                     edit: false,
                     delete: false,
                  },
                  board: {
                     access: false,
                     create: false,
                     edit: false,
                     delete: false,
                  },
                  subject: {
                     access: false,
                     create: false,
                     edit: false,
                     delete: false,
                  },
                  course: {
                     access: false,
                     create: false,
                     edit: false,
                     delete: false,
                  },
                  proposal: {
                     access: false,
                     create: false,
                     update: false,
                     contactInfoupdate: false,
                     contactStatusupdate: false,
                     delete: false,
                  },
                  dashboard: {
                     candidateStatistics: false,
                     jobStatistics: false,
                  },
               };
               res.data.permissions.forEach((permission) => {
                  //Report
                  if (permission.slug == "skill.wise.candidate.report.access") {
                     permissions.report.skillWiseCandidate = true;
                  }
                  if (permission.slug == "company.wise.job.placement.report.access") {
                     permissions.report.companyWiseJobPlacement = true;
                  }

                  //Job permission
                  if (permission.slug == "dashboard.job.statistics.access") {
                     permissions.dashboard.jobStatistics = true;
                  }

                  // candidate Permission
                  if (permission.slug == "dashboard.candidate.statistics.access") {
                     permissions.dashboard.candidateStatistics = true;
                  }
                  if (permission.slug == "role.access") {
                     permissions.role.access = true;
                  }
                  if (permission.slug == "role.create") {
                     permissions.role.create = true;
                  }
                  if (permission.slug == "role.edit") {
                     permissions.role.edit = true;
                  }
                  if (permission.slug == "role.delete") {
                     permissions.role.delete = true;
                  }
                  if (permission.slug == "candidate.access") {
                     permissions.candidate.access = true;
                  }
                  if (permission.slug == "candidate.create") {
                     permissions.candidate.create = true;
                  }
                  if (permission.slug == "candidate.edit") {
                     permissions.candidate.edit = true;
                  }
                  if (permission.slug == "candidate.destroy") {
                     permissions.candidate.delete = true;
                  }
                  if (permission.slug == "candidate.approved") {
                     permissions.candidate.approved = true;
                  }

                  // division Permission
                  if (permission.slug == "division.access") {
                     permissions.division.access = true;
                  }
                  if (permission.slug == "division.create") {
                     permissions.division.create = true;
                  }
                  if (permission.slug == "division.edit") {
                     permissions.division.edit = true;
                  }
                  if (permission.slug == "division.destroy") {
                     permissions.division.delete = true;
                  }
                  // district Permission
                  if (permission.slug == "district.access") {
                     permissions.district.access = true;
                  }
                  if (permission.slug == "district.create") {
                     permissions.district.create = true;
                  }
                  if (permission.slug == "district.edit") {
                     permissions.district.edit = true;
                  }
                  if (permission.slug == "district.destroy") {
                     permissions.district.delete = true;
                  }
                  // upazila Permission
                  if (permission.slug == "upazila.access") {
                     permissions.upazila.access = true;
                  }
                  if (permission.slug == "upazila.create") {
                     permissions.upazila.create = true;
                  }
                  if (permission.slug == "upazila.edit") {
                     permissions.upazila.edit = true;
                  }
                  if (permission.slug == "upazila.destroy") {
                     permissions.upazila.delete = true;
                  }

                  // User Permission
                  if (permission.slug == "level.access") {
                     permissions.level.access = true;
                  }
                  if (permission.slug == "level.create") {
                     permissions.level.create = true;
                  }
                  if (permission.slug == "level.edit") {
                     permissions.level.edit = true;
                  }
                  if (permission.slug == "level.destroy") {
                     permissions.level.delete = true;
                  }
                  if (permission.slug == "skill.access") {
                     permissions.skill.access = true;
                  }
                  if (permission.slug == "skill.create") {
                     permissions.skill.create = true;
                  }
                  if (permission.slug == "skill.edit") {
                     permissions.skill.edit = true;
                  }
                  if (permission.slug == "skill.destroy") {
                     permissions.skill.delete = true;
                  }
                  if (permission.slug == "link.type.access") {
                     permissions.url_info.access = true;
                  }
                  if (permission.slug == "link.type.create") {
                     permissions.url_info.create = true;
                  }
                  if (permission.slug == "link.type.edit") {
                     permissions.url_info.edit = true;
                  }
                  if (permission.slug == "link.type.destroy") {
                     permissions.url_info.delete = true;
                  }

                  // thread access
                  if (permission.slug == "thread.access") {
                     permissions.thread.access = true;
                  }

                  if (permission.slug == "thread.manage") {
                     permissions.thread.manage = true;
                  }

                  //industry access
                  if (permission.slug == "industry.access") {
                     permissions.industry.access = true;
                  }

                  if (permission.slug == "industry.create") {
                     permissions.industry.create = true;
                  }

                  if (permission.slug == "industry.edit") {
                     permissions.industry.edit = true;
                  }
                  if (permission.slug == "industry.destroy") {
                     permissions.industry.delete = true;
                  }

                  if (permission.slug == "app.user.access") {
                     permissions.user.access = true;
                  }
                  if (permission.slug == "app.user.create") {
                     permissions.user.create = true;
                  }
                  if (permission.slug == "app.user.edit") {
                     permissions.user.edit = true;
                  }
                  if (permission.slug == "app.user.destroy") {
                     permissions.user.delete = true;
                  }

                  //Job access

                  if (permission.slug == "company.job.access") {
                     permissions.jobs.access = true;
                  }
                  if (permission.slug == "company.job.create") {
                     permissions.jobs.create = true;
                  }
                  if (permission.slug == "company.job.edit") {
                     permissions.jobs.edit = true;
                  }
                  if (permission.slug == "company.job.destroy") {
                     permissions.jobs.delete = true;
                  }

                  //company access
                  if (permission.slug == "company.access") {
                     permissions.company.access = true;
                  }
                  if (permission.slug == "company.create") {
                     permissions.company.create = true;
                  }
                  if (permission.slug == "company.edit") {
                     permissions.company.edit = true;
                  }
                  if (permission.slug == "company.destroy") {
                     permissions.company.delete = true;
                  }

                  //Education Board access
                  if (permission.slug == "education.board.access") {
                     permissions.board.access = true;
                  }
                  if (permission.slug == "education.board.create") {
                     permissions.board.create = true;
                  }
                  if (permission.slug == "education.board.edit") {
                     permissions.board.edit = true;
                  }
                  if (permission.slug == "education.board.destroy") {
                     permissions.board.delete = true;
                  }

                  //Education Subject access
                  if (permission.slug == "education.subject.access") {
                     permissions.subject.access = true;
                  }
                  if (permission.slug == "education.subject.create") {
                     permissions.subject.create = true;
                  }
                  if (permission.slug == "education.subject.edit") {
                     permissions.subject.edit = true;
                  }
                  if (permission.slug == "education.subject.destroy") {
                     permissions.subject.delete = true;
                  }

                  //Education Subject access
                  if (permission.slug == "course.access") {
                     permissions.course.access = true;
                  }
                  if (permission.slug == "course.create") {
                     permissions.course.create = true;
                  }
                  if (permission.slug == "course.edit") {
                     permissions.course.edit = true;
                  }
                  if (permission.slug == "course.destroy") {
                     permissions.course.delete = true;
                  }

                  //Company Proposal access
                  if (permission.slug == "company.proposal.access") {
                     permissions.proposal.access = true;
                  }
                  if (permission.slug == "company.proposal.create") {
                     permissions.proposal.create = true;
                  }
                  if (permission.slug == "company.proposal.update") {
                     permissions.proposal.update = true;
                  }
                  if (permission.slug == "company.proposal.contact.info.update") {
                     permissions.proposal.contactInfoupdate = true;
                  }
                  if (permission.slug == "company.contact.status.update") {
                     permissions.proposal.contactStatusupdate = true;
                  }
                  if (permission.slug == "company.proposal.destroy") {
                     permissions.proposal.delete = true;
                  }

                  //User access
                  if (permission.slug == "user.access") {
                     permissions.user.access = true;
                  }
                  if (permission.slug == "user.create") {
                     permissions.user.create = true;
                  }
                  if (permission.slug == "user.edit") {
                     permissions.user.edit = true;
                  }
                  if (permission.slug == "user.destroy") {
                     permissions.user.delete = true;
                  }
               });
               content.commit("getPermissions", permissions);
            })
            .catch((e) => {
               console.log(e);
            });
      },
   },
};
